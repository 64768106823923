function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
export var POST_WPSUBSCRIPTION_REQUEST = "POST_WPSUBSCRIPTION_REQUEST";
export var POST_WPSUBSCRIPTION_SUCCESS = "POST_WPSUBSCRIPTION_SUCCESS";
export var POST_WPSUBSCRIPTION_FAILURE = "POST_WPSUBSCRIPTION_FAILURE";
export var RESET_POST_WPSUBSCRIPTION = "RESET_POST_WPSUBSCRIPTION";
export var postWpSubscription = function postWpSubscription(wpSubscription) {
  return _defineProperty({
    wpSubscription: wpSubscription
  }, CALL_API, {
    types: [POST_WPSUBSCRIPTION_REQUEST, POST_WPSUBSCRIPTION_SUCCESS, POST_WPSUBSCRIPTION_FAILURE],
    endpoint: "wpSubscriptions",
    init: {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(wpSubscription),
      credentials: "same-origin"
    }
  });
};
export var postWpSubscriptionFailure = function postWpSubscriptionFailure(message) {
  return {
    response: {
      message: message
    },
    type: POST_WPSUBSCRIPTION_FAILURE
  };
};
export var resetPostPreferences = function resetPostPreferences() {
  return {
    type: RESET_POST_WPSUBSCRIPTION
  };
};
export var DELETE_WPSUBSCRIPTION_REQUEST = "DELETE_WPSUBSCRIPTION_REQUEST";
export var DELETE_WPSUBSCRIPTION_SUCCESS = "DELETE_WPSUBSCRIPTION_SUCCESS";
export var DELETE_WPSUBSCRIPTION_FAILURE = "DELETE_WPSUBSCRIPTION_FAILURE";
export var RESET_DELETE_WPSUBSCRIPTION = "RESET_DELETE_WPSUBSCRIPTION";
export var deleteWpSubscription = function deleteWpSubscription(wpSubscription) {
  return _defineProperty({
    wpSubscription: wpSubscription
  }, CALL_API, {
    types: [DELETE_WPSUBSCRIPTION_REQUEST, DELETE_WPSUBSCRIPTION_SUCCESS, DELETE_WPSUBSCRIPTION_FAILURE],
    endpoint: "wpSubscriptions/".concat(wpSubscription.id),
    init: {
      method: "DELETE",
      credentials: "same-origin"
    }
  });
};
export var resetDeleteWpSubscription = function resetDeleteWpSubscription() {
  return {
    type: RESET_DELETE_WPSUBSCRIPTION
  };
};