import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var eventListItemApiCalls = {
  fetchEventListItemsByUserIdAndEventTypeIdIn: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.userId, action.eventTypeIds].join("-");
    },
    types: [ActionTypes.GET_EVENTLISTITEMSBYUSERIDANDEVENTTYPEIDIN_REQUEST, ActionTypes.GET_EVENTLISTITEMSBYUSERIDANDEVENTTYPEIDIN_SUCCESS, ActionTypes.GET_EVENTLISTITEMSBYUSERIDANDEVENTTYPEIDIN_FAILURE, ActionTypes.RESET_GET_EVENTLISTITEMSBYUSERIDANDEVENTTYPEIDIN]
  })
};
export default eventListItemApiCalls;