import { combineReducers } from "redux";
import addressEntities from "./addressEntities";
import advisorAsideViewEntities from "./advisorAsideViewEntities";
import afipActivityEntities from "./afipActivityEntities";
import balanceLineChartDatasetEntities from "./balanceLineChartDatasetEntities";
import bankAccountEntities from "./bankAccountEntities";
import bankAccountTypeEntities from "./bankAccountTypeEntities";
import bankEntities from "./bankEntities";
import bankHolidayEntities from "./bankHolidayEntities";
import bindTransactionEntities from "./bindTransactionEntities";
import bookmarkEntities from "./bookmarkEntities";
import bookmarkListItemEntities from "./bookmarkListItemEntities";
import boostListItemEntities from "./boostListItemEntities";
import boostBrokerCurrencyBalanceEntities from "./boostBrokerCurrencyBalanceEntities";
import boostEntities from "./boostEntities";
import boostsViewEntities from "./boostsViewEntities";
import brokerCountBalanceEntities from "./brokerCountBalanceEntities";
import brokerCurrencyBalanceEntities from "./brokerCurrencyBalanceEntities";
import brokerCurrencyGoalInvestmentItemUserBalanceEntities from "./brokerCurrencyGoalInvestmentItemUserBalanceEntities";
import brokerCurrencyGoalInvestmentItemMarketUserBalanceEntities from "./brokerCurrencyGoalInvestmentItemMarketUserBalanceEntities";
import brokerCurrencyGoalUserBalanceEntities from "./brokerCurrencyGoalUserBalanceEntities";
import brokerCurrencyInvestmentItemBalanceEntities from "./brokerCurrencyInvestmentItemBalanceEntities";
import brokerCurrencyInvestmentItemUserBalanceEntities from "./brokerCurrencyInvestmentItemUserBalanceEntities";
import brokerCurrencyUserBalanceEntities from "./brokerCurrencyUserBalanceEntities";
import brokerEntities from "./brokerEntities";
import brokerFollowerEntities from "./brokerFollowerEntities";
import brokerMentionListItemEntities from "./brokerMentionListItemEntities";
import brokerMentionEntities from "./brokerMentionEntities";
import brokerViewEntities from "./brokerViewEntities";
import cafciClaseEntities from "./cafciClaseEntities";
import cafciClaseViewEntities from "./cafciClaseViewEntities";
import cafciCarteraEntities from "./cafciCarteraEntities";
import cafciPrecioEntities from "./cafciPrecioEntities";
import cafciPrecioLineChartEntities from "./cafciPrecioLineChartEntities";
import campaignEntities from "./campaignEntities";
import cartEntities from "./cartEntities";
import cartViewEntities from "./cartViewEntities";
import choiceEntities from "./choiceEntities";
import cityEntities from "./cityEntities";
import commissionTypeEntities from "./commissionTypeEntities";
import countBalanceEntities from "./countBalanceEntities";
import countryCountBalanceEntities from "./countryCountBalanceEntities";
import countryEntities from "./countryEntities";
import currencyBalanceEntities from "./currencyBalanceEntities";
import currencyEntities from "./currencyEntities";
import currencyGoalBalanceEntities from "./currencyGoalBalanceEntities";
import currencyGoalInvestmentItemBalanceEntities from "./currencyGoalInvestmentItemBalanceEntities";
import currencyGoalInvestmentItemUserBalanceEntities from "./currencyGoalInvestmentItemUserBalanceEntities";
import currencyGoalUserBalanceEntities from "./currencyGoalUserBalanceEntities";
import currencyGoalUserBalanceLineChartEntities from "./currencyGoalUserBalanceLineChartEntities";
import currencyInvestmentItemUserBalanceEntities from "./currencyInvestmentItemUserBalanceEntities";
import currencyUserBalanceEntities from "./currencyUserBalanceEntities";
import currencyVariantInvestmentItemEntities from "./currencyVariantInvestmentItemEntities";
import dashboardEntity from "./dashboardEntity";
import dashboardStatsEntity from "./dashboardStatsEntity";
import displayEntities from "./displayEntities";
import distributionTabEntities from "./distributionTabEntities";
import documentEntities from "./documentEntities";
import documentTypeEntities from "./documentTypeEntities";
import employeeEntities from "./employeeEntities";
import eventListItemEntities from "./eventListItemEntities";
import eventEntities from "./eventEntities";
import eventReactionEntities from "./eventReactionEntities";
import eventTypeEntities from "./eventTypeEntities";
import externalBalanceEntities from "./externalBalanceEntities";
import genderEntities from "./genderEntities";
import goalAmountEntities from "./goalAmountEntities";
import goalEntities from "./goalEntities";
import goalFollowerEntities from "./goalFollowerEntities";
import goalMemberEntities from "./goalMemberEntities";
import goalMemberListItemEntities from "./goalMemberListItemEntities";
import goalMembersTabEntities from "./goalMembersTabEntities";
import goalMembersViewEntities from "./goalMembersViewEntities";
import goalMentionListItemEntities from "./goalMentionListItemEntities";
import goalMentionEntities from "./goalMentionEntities";
import goalTypeEntities from "./goalTypeEntities";
import goalViewEntities from "./goalViewEntities";
import groupEntities from "./groupEntities";
import hashtagEntities from "./hashtagEntities";
import hashtagFollowerEntities from "./hashtagFollowerEntities";
import hashtagMentionEntities from "./hashtagMentionEntities";
import hashtagMentionListItemEntities from "./hashtagMentionListItemEntities";
import hashtagViewEntities from "./hashtagViewEntities";
import incomeTypeEntities from "./incomeTypeEntities";
import industryEntities from "./industryEntities";
import invertirOnlinePriceEntities from "./invertirOnlinePriceEntities";
import invertirOnlinePriceLineChartEntities from "./invertirOnlinePriceLineChartEntities";
import invertirOnlineTitleEntities from "./invertirOnlineTitleEntities";
import invertirOnlineTitleViewEntities from "./invertirOnlineTitleViewEntities";
import investmentAccountEntities from "./investmentAccountEntities";
import investmentItemAvailabilityEntities from "./investmentItemAvailabilityEntities";
import investmentItemEntities from "./investmentItemEntities";
import investmentItemListItemEntities from "./investmentItemListItemEntities";
import investmentItemFollowerEntities from "./investmentItemFollowerEntities";
import investmentItemMentionListItemEntities from "./investmentItemMentionListItemEntities";
import investmentItemMentionEntities from "./investmentItemMentionEntities";
import investmentItemsViewEntities from "./investmentItemsViewEntities";
import investmentItemTypeEntities from "./investmentItemTypeEntities";
import investmentItemViewEntities from "./investmentItemViewEntities";
import investmentPartyEntity from "./investmentPartyEntity";
import investmentPartyViewEntities from "./investmentPartyViewEntities";
import investmentPlanEntities from "./investmentPlanEntities";
import investmentPreferenceEntities from "./investmentPreferenceEntities";
import ivaTaxpayerTypeEntities from "./ivaTaxpayerTypeEntities";
import languageEntities from "./languageEntities";
import localityEntities from "./localityEntities";
import maritalStatusEntities from "./maritalStatusEntities";
import marketVariantInvestmentItemEntities from "./marketVariantInvestmentItemEntities";
import myBrokersDashboardEntities from "./myBrokersDashboardEntities";
import myBrokersDashboardStatsEntities from "./myBrokersDashboardStatsEntities";
import eventsAsideViewEntities from "./eventsAsideViewEntities";
import messageEntities from "./messageEntities";
import messageStateEntities from "./messageStateEntities";
import noMemberListItemEntities from "./noMemberListItemEntities";
import notificationEntities from "./notificationEntities";
import notificationListItemEntities from "./notificationListItemEntities";
import notificationTabEntities from "./notificationTabEntities";
import numericConventionEntities from "./numericConventionEntities";
import operationEntities from "./operationEntities";
import operationFormEntities from "./operationFormEntities";
import participantEntities from "./participantEntities";
import pendingDepositsViewEntities from "./pendingDepositsViewEntities";
import personEntities from "./personEntities";
import pickEntities from "./pickEntities";
import postListItemEntities from "./postListItemEntities";
import postEntities from "./postEntities";
import postReactionEntities from "./postReactionEntities";
import postReactionListItemEntities from "./postReactionListItemEntities";
import priceEntities from "./priceEntities";
import priceForecastEntities from "./priceForecastEntities";
import priceLineChartDatasetEntities from "./priceLineChartDatasetEntities";
import priceStatsEntities from "./priceStatsEntities";
import provinceEntities from "./provinceEntities";
import riskProfileEntities from "./riskProfileEntities";
import sentimentTypeEntities from "./sentimentTypeEntities";
import timeZoneEntities from "./timeZoneEntities";
import transactionEntities from "./transactionEntities";
import typingEntities from "./typingEntities";
import uiPreferencesEntities from "./uiPreferencesEntities";
import unitDecimalScaleEntities from "./unitDecimalScaleEntities";
import userEntities from "./userEntities";
import userFollowerEntities from "./userFollowerEntities";
import userFollowerListItemEntities from "./userFollowerListItemEntities";
import userMentionListItemEntities from "./userMentionListItemEntities";
import userMentionEntities from "./userMentionEntities";
import userStatsEntities from "./userStatsEntities";
import usersViewEntities from "./usersViewEntities";
import userViewEntities from "./userViewEntities";
import variantInvestmentItemEntities from "./variantInvestmentItemEntities";
import walletViewEntities from "./walletViewEntities";
import watchlistItemEntities from "./watchlistItemEntities";
import watchlistItemListItemEntities from "./watchlistItemListItemEntities";
import wireTransferEntities from "./wireTransferEntities";
import workingStatusEntities from "./workingStatusEntities";
import wpSubscriptionEntities from "./wpSubscriptionEntities";
import wsMessageEntities from "./wsMessageEntities";
var entities = combineReducers({
  addresses: addressEntities,
  advisorAsideViews: advisorAsideViewEntities,
  afipActivities: afipActivityEntities,
  balanceLineChartDatasets: balanceLineChartDatasetEntities,
  bankAccounts: bankAccountEntities,
  bankAccountTypes: bankAccountTypeEntities,
  banks: bankEntities,
  bankHolidays: bankHolidayEntities,
  bindTransactions: bindTransactionEntities,
  bookmarks: bookmarkEntities,
  bookmarkListItems: bookmarkListItemEntities,
  boostListItems: boostListItemEntities,
  boostBrokerCurrencyBalances: boostBrokerCurrencyBalanceEntities,
  boosts: boostEntities,
  boostsViews: boostsViewEntities,
  brokerCountBalances: brokerCountBalanceEntities,
  brokerCurrencyBalances: brokerCurrencyBalanceEntities,
  brokerCurrencyGoalInvestmentItemUserBalances: brokerCurrencyGoalInvestmentItemUserBalanceEntities,
  brokerCurrencyGoalInvestmentItemMarketUserBalances: brokerCurrencyGoalInvestmentItemMarketUserBalanceEntities,
  brokerCurrencyGoalUserBalances: brokerCurrencyGoalUserBalanceEntities,
  brokerCurrencyInvestmentItemBalances: brokerCurrencyInvestmentItemBalanceEntities,
  brokerCurrencyInvestmentItemUserBalances: brokerCurrencyInvestmentItemUserBalanceEntities,
  brokerCurrencyUserBalances: brokerCurrencyUserBalanceEntities,
  brokerFollowers: brokerFollowerEntities,
  brokerMentionListItems: brokerMentionListItemEntities,
  brokerMentions: brokerMentionEntities,
  brokers: brokerEntities,
  brokerViews: brokerViewEntities,
  cafciClases: cafciClaseEntities,
  cafciClaseViews: cafciClaseViewEntities,
  cafciCarteras: cafciCarteraEntities,
  cafciPrecioLineCharts: cafciPrecioLineChartEntities,
  cafciPrecios: cafciPrecioEntities,
  campaigns: campaignEntities,
  carts: cartEntities,
  cartViews: cartViewEntities,
  choices: choiceEntities,
  cities: cityEntities,
  commissionTypes: commissionTypeEntities,
  countBalances: countBalanceEntities,
  countries: countryEntities,
  countryCountBalances: countryCountBalanceEntities,
  currencyBalances: currencyBalanceEntities,
  currencies: currencyEntities,
  currencyGoalBalances: currencyGoalBalanceEntities,
  currencyGoalInvestmentItemBalances: currencyGoalInvestmentItemBalanceEntities,
  currencyGoalInvestmentItemUserBalances: currencyGoalInvestmentItemUserBalanceEntities,
  currencyGoalUserBalanceLineCharts: currencyGoalUserBalanceLineChartEntities,
  currencyGoalUserBalances: currencyGoalUserBalanceEntities,
  currencyInvestmentItemUserBalances: currencyInvestmentItemUserBalanceEntities,
  currencyUserBalances: currencyUserBalanceEntities,
  currencyVariantInvestmentItems: currencyVariantInvestmentItemEntities,
  dashboard: dashboardEntity,
  dashboardStats: dashboardStatsEntity,
  displays: displayEntities,
  distributionTabs: distributionTabEntities,
  documents: documentEntities,
  documentTypes: documentTypeEntities,
  employees: employeeEntities,
  eventListItems: eventListItemEntities,
  events: eventEntities,
  eventReactions: eventReactionEntities,
  eventTypes: eventTypeEntities,
  eventsAsideViews: eventsAsideViewEntities,
  externalBalances: externalBalanceEntities,
  genders: genderEntities,
  goalAmounts: goalAmountEntities,
  goals: goalEntities,
  goalFollowers: goalFollowerEntities,
  goalMembers: goalMemberEntities,
  goalMemberListItems: goalMemberListItemEntities,
  goalMembersTabs: goalMembersTabEntities,
  goalMembersViews: goalMembersViewEntities,
  goalMentionListItems: goalMentionListItemEntities,
  goalMentions: goalMentionEntities,
  goalTypes: goalTypeEntities,
  goalViews: goalViewEntities,
  groups: groupEntities,
  hashtags: hashtagEntities,
  hashtagFollowers: hashtagFollowerEntities,
  hashtagMentions: hashtagMentionEntities,
  hashtagMentionListItems: hashtagMentionListItemEntities,
  hashtagViews: hashtagViewEntities,
  incomeTypes: incomeTypeEntities,
  industries: industryEntities,
  invertirOnlinePriceLineCharts: invertirOnlinePriceLineChartEntities,
  invertirOnlinePrices: invertirOnlinePriceEntities,
  invertirOnlineTitles: invertirOnlineTitleEntities,
  invertirOnlineTitleViews: invertirOnlineTitleViewEntities,
  investmentAccounts: investmentAccountEntities,
  investmentItemAvailabilities: investmentItemAvailabilityEntities,
  investmentItemListItems: investmentItemListItemEntities,
  investmentItemFollowers: investmentItemFollowerEntities,
  investmentItems: investmentItemEntities,
  investmentItemViews: investmentItemViewEntities,
  investmentItemMentionListItems: investmentItemMentionListItemEntities,
  investmentItemMentions: investmentItemMentionEntities,
  investmentItemsViews: investmentItemsViewEntities,
  investmentItemTypes: investmentItemTypeEntities,
  investmentParties: investmentPartyEntity,
  investmentPartyViews: investmentPartyViewEntities,
  investmentPlans: investmentPlanEntities,
  investmentPreferences: investmentPreferenceEntities,
  ivaTaxpayerTypes: ivaTaxpayerTypeEntities,
  languages: languageEntities,
  localities: localityEntities,
  maritalStatuses: maritalStatusEntities,
  marketVariantInvestmentItems: marketVariantInvestmentItemEntities,
  messages: messageEntities,
  messageStates: messageStateEntities,
  myBrokersDashboards: myBrokersDashboardEntities,
  myBrokersDashboardStats: myBrokersDashboardStatsEntities,
  noMemberListItems: noMemberListItemEntities,
  notifications: notificationEntities,
  notificationListItems: notificationListItemEntities,
  notificationTabs: notificationTabEntities,
  numericConventions: numericConventionEntities,
  participants: participantEntities,
  pendingDepositsViews: pendingDepositsViewEntities,
  persons: personEntities,
  picks: pickEntities,
  postListItems: postListItemEntities,
  posts: postEntities,
  postReactions: postReactionEntities,
  postReactionListItems: postReactionListItemEntities,
  priceLineChartDatasets: priceLineChartDatasetEntities,
  priceStats: priceStatsEntities,
  priceForecasts: priceForecastEntities,
  prices: priceEntities,
  provinces: provinceEntities,
  operationForms: operationFormEntities,
  operations: operationEntities,
  riskProfiles: riskProfileEntities,
  sentimentTypes: sentimentTypeEntities,
  timeZones: timeZoneEntities,
  transactions: transactionEntities,
  typings: typingEntities,
  unitDecimalScales: unitDecimalScaleEntities,
  uiPreferences: uiPreferencesEntities,
  users: userEntities,
  userFollowers: userFollowerEntities,
  userFollowerListItems: userFollowerListItemEntities,
  userMentionListItems: userMentionListItemEntities,
  userMentions: userMentionEntities,
  userStats: userStatsEntities,
  usersViews: usersViewEntities,
  userViews: userViewEntities,
  variantInvestmentItems: variantInvestmentItemEntities,
  walletViews: walletViewEntities,
  watchlistItems: watchlistItemEntities,
  watchlistItemListItems: watchlistItemListItemEntities,
  wireTransfers: wireTransferEntities,
  workingStatuses: workingStatusEntities,
  wpSubscriptions: wpSubscriptionEntities,
  wsMessages: wsMessageEntities
});
export default entities;