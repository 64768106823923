import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var aiUserApiCalls = {
  fetchAiUsersByEnabledAtNotNullOrderByGroupMembersCountDesc: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_AIUSERSBYENABLEDATNOTNULLORDERBYGROUPMEMBERSCOUNTDESC_REQUEST, ActionTypes.GET_AIUSERSBYENABLEDATNOTNULLORDERBYGROUPMEMBERSCOUNTDESC_SUCCESS, ActionTypes.GET_AIUSERSBYENABLEDATNOTNULLORDERBYGROUPMEMBERSCOUNTDESC_FAILURE, ActionTypes.RESET_GET_AIUSERSBYENABLEDATNOTNULLORDERBYGROUPMEMBERSCOUNTDESC]
  })
};
export default aiUserApiCalls;