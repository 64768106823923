import status from "./status";
import * as ActionTypes from "../../actions";

//Updates the status apiCalls data for different api call.
var goalMembersViewApiCalls = {
  loadGoalMembersViewByUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.userId.toString();
    },
    types: [ActionTypes.GET_GOALMEMBERSVIEWBYUSERID_REQUEST, ActionTypes.GET_GOALMEMBERSVIEWBYUSERID_SUCCESS, ActionTypes.GET_GOALMEMBERSVIEWBYUSERID_FAILURE, ActionTypes.RESET_GET_GOALMEMBERSVIEWBYUSERID]
  })
};
export default goalMembersViewApiCalls;