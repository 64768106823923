import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var goalMemberListItemApiCalls = {
  fetchGoalMemberListItemsByMemberUserIdAndMemberStates: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.memberUserId, action.memberStates].join("-");
    },
    types: [ActionTypes.GET_GOALMEMBERLISTITEMSBYMEMBERUSERIDANDMEMBERSTATES_REQUEST, ActionTypes.GET_GOALMEMBERLISTITEMSBYMEMBERUSERIDANDMEMBERSTATES_SUCCESS, ActionTypes.GET_GOALMEMBERLISTITEMSBYMEMBERUSERIDANDMEMBERSTATES_FAILURE, ActionTypes.RESET_GET_GOALMEMBERLISTITEMSBYMEMBERUSERIDANDMEMBERSTATES]
  })
};
export default goalMemberListItemApiCalls;