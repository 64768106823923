function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_GOALMEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_REQUEST = "GET_GOALMEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_REQUEST";
export var GET_GOALMEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_SUCCESS = "GET_GOALMEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_SUCCESS";
export var GET_GOALMEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_FAILURE = "GET_GOALMEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_FAILURE";
export var RESET_GET_GOALMEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL = "RESET_GET_GOALMEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL";

// Fetches goalMembers by a particular user and states enriched for a particular boost from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
export var fetchGoalMembersByUserIdAndStateInAndConfirmedAtNotNull = function fetchGoalMembersByUserIdAndStateInAndConfirmedAtNotNull(userId, states) {
  var queryString = urlSearchParams({
    sort: "createdAt,desc",
    projection: "withGoalAndRoles",
    userId: userId,
    states: states
  });
  return _defineProperty({
    userId: userId,
    states: states
  }, CALL_API, {
    types: [GET_GOALMEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_REQUEST, GET_GOALMEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_SUCCESS, GET_GOALMEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_FAILURE],
    endpoint: "goalMembers/search/findByUser_IdAndStateInAndConfirmedAtNotNullAndDeletedAtIsNull".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches goalMembers by a particular user and states enriched for a particular boost unless it is cached.
// Relies on Redux Thunk middleware.
export var loadGoalMembersByUserIdAndStateInAndConfirmedAtNotNull = function loadGoalMembersByUserIdAndStateInAndConfirmedAtNotNull(userId, states, forceUpdate) {
  return function (dispatch, getState) {
    var key = [userId, states].join("-");
    var goalMembers = getState().entities.goalMembers.byUserIdAndStateInAndConfirmedAtNotNull[key];
    if (goalMembers && !forceUpdate) return null;
    var call = getState().apiCalls.loadGoalMembersByUserIdAndStateInAndConfirmedAtNotNull[key];
    if (call && call.isFetching) return null;
    return dispatch(fetchGoalMembersByUserIdAndStateInAndConfirmedAtNotNull(userId, states));
  };
};
export var GET_GOALMEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL_REQUEST = "GET_GOALMEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL_REQUEST";
export var GET_GOALMEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL_SUCCESS = "GET_GOALMEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL_SUCCESS";
export var GET_GOALMEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL_FAILURE = "GET_GOALMEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL_FAILURE";
export var RESET_GET_GOALMEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL = "RESET_GET_GOALMEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL";

// Fetches goalMembers by a particular user and states enriched from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchGoalMembersByUserIdAndConfirmedAtNotNull = function fetchGoalMembersByUserIdAndConfirmedAtNotNull(userId) {
  var queryString = urlSearchParams({
    sort: "createdAt,desc",
    projection: "withGoalAndRoles",
    userId: userId
  });
  return _defineProperty({
    userId: userId
  }, CALL_API, {
    types: [GET_GOALMEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL_REQUEST, GET_GOALMEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL_SUCCESS, GET_GOALMEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL_FAILURE],
    endpoint: "goalMembers/search/findByUser_IdAndConfirmedAtNotNull".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches goalMembers by a particular user and states enriched unless it is cached.
// Relies on Redux Thunk middleware.
export var loadGoalMembersByUserIdAndConfirmedAtNotNull = function loadGoalMembersByUserIdAndConfirmedAtNotNull(userId, forceUpdate) {
  return function (dispatch, getState) {
    var goalMembers = getState().entities.goalMembers.byUserIdAndConfirmedAtNotNull[userId];
    if (goalMembers && !forceUpdate) return null;
    return dispatch(fetchGoalMembersByUserIdAndConfirmedAtNotNull(userId));
  };
};
export var GET_GOALMEMBERBYGOALIDANDUSERID_REQUEST = "GET_GOALMEMBERBYGOALIDANDUSERID_REQUEST";
export var GET_GOALMEMBERBYGOALIDANDUSERID_SUCCESS = "GET_GOALMEMBERBYGOALIDANDUSERID_SUCCESS";
export var GET_GOALMEMBERBYGOALIDANDUSERID_FAILURE = "GET_GOALMEMBERBYGOALIDANDUSERID_FAILURE";
export var RESET_GET_GOALMEMBERBYGOALIDANDUSERID = "RESET_GET_GOALMEMBERBYGOALIDANDUSERID";

// Fetches a goalMember for a particular user and goal from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
export var fetchGoalMemberByGoalIdAndUserId = function fetchGoalMemberByGoalIdAndUserId(goalId, userId) {
  var queryString = urlSearchParams({
    projection: "full",
    goalId: goalId,
    userId: userId
  });
  return _defineProperty({
    goalId: goalId,
    userId: userId
  }, CALL_API, {
    types: [GET_GOALMEMBERBYGOALIDANDUSERID_REQUEST, GET_GOALMEMBERBYGOALIDANDUSERID_SUCCESS, GET_GOALMEMBERBYGOALIDANDUSERID_FAILURE],
    endpoint: "goalMembers/search/findByGoal_IdAndUser_Id".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};
export var DELETE_GOALMEMBER_REQUEST = "DELETE_GOALMEMBER_REQUEST";
export var DELETE_GOALMEMBER_SUCCESS = "DELETE_GOALMEMBER_SUCCESS";
export var DELETE_GOALMEMBER_FAILURE = "DELETE_GOALMEMBER_FAILURE";
export var RESET_DELETE_GOALMEMBER = "RESET_DELETE_GOALMEMBER";
export var deleteGoalMember = function deleteGoalMember(goalMember) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      goalMember: goalMember
    }, CALL_API, {
      types: [DELETE_GOALMEMBER_REQUEST, DELETE_GOALMEMBER_SUCCESS, DELETE_GOALMEMBER_FAILURE],
      endpoint: "goalMembers/".concat(goalMember.id),
      init: {
        method: "DELETE",
        credentials: "same-origin"
      }
    }));
  };
};
export var resetDeleteMember = function resetDeleteMember() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_DELETE_GOALMEMBER
    });
  };
};
export var POST_GOALMEMBER_REQUEST = "POST_GOALMEMBER_REQUEST";
export var POST_GOALMEMBER_SUCCESS = "POST_GOALMEMBER_SUCCESS";
export var POST_GOALMEMBER_FAILURE = "POST_GOALMEMBER_FAILURE";
export var RESET_POST_GOALMEMBER = "RESET_POST_GOALMEMBER";
export var postGoalMember = function postGoalMember(goalMember) {
  return function (dispatch, getState) {
    var endpoint = "goalMembers";
    if (goalMember.id) endpoint += "/".concat(goalMember.id);
    return dispatch(_defineProperty({
      goalMember: goalMember
    }, CALL_API, {
      types: [POST_GOALMEMBER_REQUEST, POST_GOALMEMBER_SUCCESS, POST_GOALMEMBER_FAILURE],
      endpoint: endpoint,
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(goalMember),
        credentials: "same-origin"
      }
    }));
  };
};
export var POST_GOALMEMBEREXCLUSIONPREFERENCES_REQUEST = "POST_GOALMEMBEREXCLUSIONPREFERENCES_REQUEST";
export var POST_GOALMEMBEREXCLUSIONPREFERENCES_SUCCESS = "POST_GOALMEMBEREXCLUSIONPREFERENCES_SUCCESS";
export var POST_GOALMEMBEREXCLUSIONPREFERENCES_FAILURE = "POST_GOALMEMBEREXCLUSIONPREFERENCES_FAILURE";
export var RESET_POST_GOALMEMBEREXCLUSIONPREFERENCES = "RESET_POST_GOALMEMBEREXCLUSIONPREFERENCES";
export var postGoalMemberExclusionPreferences = function postGoalMemberExclusionPreferences(goalMember, exclusionPreferences) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      goalMember: goalMember,
      exclusionPreferences: exclusionPreferences
    }, CALL_API, {
      types: [POST_GOALMEMBEREXCLUSIONPREFERENCES_REQUEST, POST_GOALMEMBEREXCLUSIONPREFERENCES_SUCCESS, POST_GOALMEMBEREXCLUSIONPREFERENCES_FAILURE],
      endpoint: "goalMembers/".concat(goalMember.id, "/exclusionPreferences"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(exclusionPreferences),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostMemberExclusionPreferences = function resetPostMemberExclusionPreferences(id) {
  return function (dispatch, getState) {
    return dispatch({
      id: id,
      type: RESET_POST_GOALMEMBEREXCLUSIONPREFERENCES
    });
  };
};