import status from "./status";
import * as ActionTypes from "../../actions";

//Updates the status apiCalls data for different api call.
var notificationListItemApiCalls = {
  loadNotificationListItemsByUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.userId.toString();
    },
    types: [ActionTypes.GET_NOTIFICATIONTOASTSBYUSERID_REQUEST, ActionTypes.GET_NOTIFICATIONTOASTSBYUSERID_SUCCESS, ActionTypes.GET_NOTIFICATIONTOASTSBYUSERID_FAILURE, ActionTypes.RESET_GET_NOTIFICATIONTOASTSBYUSERID]
  }),
  loadNotificationListItemsByUserIdAndViewedAtIsNull: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.userId.toString();
    },
    types: [ActionTypes.GET_NOTIFICATIONTOASTSBYUSERIDANDNOTIFIATIONVIEWEDATISNULL_REQUEST, ActionTypes.GET_NOTIFICATIONTOASTSBYUSERIDANDNOTIFIATIONVIEWEDATISNULL_SUCCESS, ActionTypes.GET_NOTIFICATIONTOASTSBYUSERIDANDNOTIFIATIONVIEWEDATISNULL_FAILURE, ActionTypes.RESET_GET_NOTIFICATIONTOASTSBYUSERIDANDNOTIFIATIONVIEWEDATISNULL]
  })
};
export default notificationListItemApiCalls;