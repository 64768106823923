function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
export var LOGIN_REQUEST = "LOGIN_REQUEST";
export var LOGIN_SUCCESS = "LOGIN_SUCCESS";
export var LOGIN_FAILURE = "LOGIN_FAILURE";
export var RESET_LOGIN = "RESET_LOGIN";
export var postLogin = function postLogin(username, password, rememberMe) {
  var body = new FormData();
  body.append("j_username", username);
  body.append("j_password", password);
  body.append("remember-me", rememberMe);
  return _defineProperty({}, CALL_API, {
    types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
    endpoint: "login",
    init: {
      credentials: "same-origin",
      method: "POST",
      body: body
    }
  });
};
export var loginFailure = function loginFailure(message) {
  return function (dispatch, getState) {
    return dispatch({
      response: {
        message: message
      },
      type: LOGIN_FAILURE
    });
  };
};
export var GET_AUTHENTICATIONSTATUS_REQUEST = "GET_AUTHENTICATIONSTATUS_REQUEST";
export var GET_AUTHENTICATIONSTATUS_SUCCESS = "GET_AUTHENTICATIONSTATUS_SUCCESS";
export var GET_AUTHENTICATIONSTATUS_FAILURE = "GET_AUTHENTICATIONSTATUS_FAILURE";
export var REST_GET_AUTHENTICATIONSTATUS = "REST_GET_AUTHENTICATIONSTATUS";

//Fetches login status from Front API unless it is cached.
//Relies on Redux Thunk middleware.
export var fetchAuthenticationStatus = function fetchAuthenticationStatus() {
  return _defineProperty({}, CALL_API, {
    types: [GET_AUTHENTICATIONSTATUS_REQUEST, GET_AUTHENTICATIONSTATUS_SUCCESS, GET_AUTHENTICATIONSTATUS_FAILURE],
    endpoint: "login",
    init: {
      credentials: "same-origin"
    }
  });
};
export var SET_AUTHENTICATIONSTATUS = "SET_AUTHENTICATIONSTATUS";
export var setAuthenticationStatus = function setAuthenticationStatus(authenticationStatus) {
  return function (dispatch, getState) {
    return dispatch({
      authenticationStatus: authenticationStatus,
      type: SET_AUTHENTICATIONSTATUS
    });
  };
};
export var SETREDIRECTURL = "SETREDIRECTURL";
export var setRedirectUrl = function setRedirectUrl(url) {
  return {
    url: url,
    type: SETREDIRECTURL
  };
};
export var LOGOUT_REQUEST = "LOGOUT_REQUEST";
export var LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export var LOGOUT_FAILURE = "LOGOUT_FAILURE";
export var RESET_LOGOUT = "RESET_LOGOUT";
export var logout = function logout() {
  return _defineProperty({}, CALL_API, {
    types: [LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE],
    endpoint: "logout2",
    init: {
      credentials: "same-origin"
    }
  });
};