function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_GROUPSBYMEMBERSUSERID_REQUEST = "GET_GROUPSBYMEMBERSUSERID_REQUEST";
export var GET_GROUPSBYMEMBERSUSERID_SUCCESS = "GET_GROUPSBYMEMBERSUSERID_SUCCESS";
export var GET_GROUPSBYMEMBERSUSERID_FAILURE = "GET_GROUPSBYMEMBERSUSERID_FAILURE";
export var RESET_GET_GROUPSBYMEMBERSUSERID = "RESET_GET_GROUPSBYMEMBERSUSERID";
export var fetchGroupsByMembersUserId = function fetchGroupsByMembersUserId(membersUserId, maxMessageId, sinceMessageId, count) {
  var queryString = urlSearchParams({
    membersUserId: membersUserId,
    maxMessageId: maxMessageId,
    sinceMessageId: sinceMessageId,
    count: count
  });
  return _defineProperty({
    membersUserId: membersUserId,
    maxMessageId: maxMessageId,
    sinceMessageId: sinceMessageId,
    count: count
  }, CALL_API, {
    types: [GET_GROUPSBYMEMBERSUSERID_REQUEST, GET_GROUPSBYMEMBERSUSERID_SUCCESS, GET_GROUPSBYMEMBERSUSERID_FAILURE],
    endpoint: "groups/search/findByMembersUserId".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};
export var GET_FIRSTGROUPBYMULTIFALSEANDMEMBERSUSERIDINORDERBYIDDESC_REQUEST = "GET_FIRSTGROUPBYMULTIFALSEANDMEMBERSUSERIDINORDERBYIDDESC_REQUEST";
export var GET_FIRSTGROUPBYMULTIFALSEANDMEMBERSUSERIDINORDERBYIDDESC_SUCCESS = "GET_FIRSTGROUPBYMULTIFALSEANDMEMBERSUSERIDINORDERBYIDDESC_SUCCESS";
export var GET_FIRSTGROUPBYMULTIFALSEANDMEMBERSUSERIDINORDERBYIDDESC_FAILURE = "GET_FIRSTGROUPBYMULTIFALSEANDMEMBERSUSERIDINORDERBYIDDESC_FAILURE";
export var RESET_GET_FIRSTGROUPBYMULTIFALSEANDMEMBERSUSERIDINORDERBYIDDESC = "RESET_GET_FIRSTGROUPBYMULTIFALSEANDMEMBERSUSERIDINORDERBYIDDESC";
export var findFirstByMultiFalseAndMembers_User_IdInOrderByIdDesc = function findFirstByMultiFalseAndMembers_User_IdInOrderByIdDesc(membersUserIds) {
  var queryString = urlSearchParams({
    membersUserIds: membersUserIds,
    projection: "withMembers"
  });
  return _defineProperty({
    membersUserIds: membersUserIds
  }, CALL_API, {
    types: [GET_FIRSTGROUPBYMULTIFALSEANDMEMBERSUSERIDINORDERBYIDDESC_REQUEST, GET_FIRSTGROUPBYMULTIFALSEANDMEMBERSUSERIDINORDERBYIDDESC_SUCCESS, GET_FIRSTGROUPBYMULTIFALSEANDMEMBERSUSERIDINORDERBYIDDESC_FAILURE],
    endpoint: "groups/search/findFirstByMultiFalseAndMembers_User_IdInOrderByIdDesc".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};
export var POST_GROUP_REQUEST = "POST_GROUP_REQUEST";
export var POST_GROUP_SUCCESS = "POST_GROUP_SUCCESS";
export var POST_GROUP_FAILURE = "POST_GROUP_FAILURE";
export var RESET_POST_GROUP = "RESET_POST_GROUP";
export var postGroup = function postGroup(group) {
  var endpoint = "groups";
  if (group.id) endpoint += "/".concat(group.id);
  return _defineProperty({}, CALL_API, {
    types: [POST_GROUP_REQUEST, POST_GROUP_SUCCESS, POST_GROUP_FAILURE],
    endpoint: endpoint,
    init: {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(group),
      credentials: "same-origin"
    }
  });
};
export var POST_GROUPPICTURE_REQUEST = "POST_GROUPPICTURE_REQUEST";
export var POST_GROUPPICTURE_SUCCESS = "POST_GROUPPICTURE_SUCCESS";
export var POST_GROUPPICTURE_FAILURE = "POST_GROUPPICTURE_FAILURE";
export var RESET_POST_GROUPPICTURE = "RESET_POST_GROUPPICTURE";
export var postGroupPicture = function postGroupPicture(group, picture) {
  return _defineProperty({
    group: group,
    picture: picture
  }, CALL_API, {
    types: [POST_GROUPPICTURE_REQUEST, POST_GROUPPICTURE_SUCCESS, POST_GROUPPICTURE_FAILURE],
    endpoint: "groups/".concat(group.id, "/picture"),
    init: {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(picture),
      credentials: "same-origin"
    }
  });
};