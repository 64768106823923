import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var wsMessageApiCalls = {
  fetchFirstWsMessageBySessionUserIdOrderByIdDesc: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.sessionUserId.toString();
    },
    types: [ActionTypes.GET_FIRSTWSMESSAGESBYSESSIONUSERIDORDERBYIDDESC_REQUEST, ActionTypes.GET_FIRSTWSMESSAGESBYSESSIONUSERIDORDERBYIDDESC_SUCCESS, ActionTypes.GET_FIRSTWSMESSAGESBYSESSIONUSERIDORDERBYIDDESC_FAILURE, ActionTypes.RESET_GET_FIRSTWSMESSAGESBYSESSIONUSERIDORDERBYIDDESC]
  })
};
export default wsMessageApiCalls;