function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_FIRSTWSMESSAGESBYSESSIONUSERIDORDERBYIDDESC_REQUEST = "GET_FIRSTWSMESSAGESBYSESSIONUSERIDORDERBYIDDESC_REQUEST";
export var GET_FIRSTWSMESSAGESBYSESSIONUSERIDORDERBYIDDESC_SUCCESS = "GET_FIRSTWSMESSAGESBYSESSIONUSERIDORDERBYIDDESC_SUCCESS";
export var GET_FIRSTWSMESSAGESBYSESSIONUSERIDORDERBYIDDESC_FAILURE = "GET_FIRSTWSMESSAGESBYSESSIONUSERIDORDERBYIDDESC_FAILURE";
export var RESET_GET_FIRSTWSMESSAGESBYSESSIONUSERIDORDERBYIDDESC = "RESET_GET_FIRSTWSMESSAGESBYSESSIONUSERIDORDERBYIDDESC";
export var fetchFirstWsMessageBySessionUserIdOrderByIdDesc = function fetchFirstWsMessageBySessionUserIdOrderByIdDesc(sessionUserId) {
  var queryString = urlSearchParams({
    sessionUserId: sessionUserId,
    projection: "basic"
  });
  return _defineProperty({
    sessionUserId: sessionUserId
  }, CALL_API, {
    types: [GET_FIRSTWSMESSAGESBYSESSIONUSERIDORDERBYIDDESC_REQUEST, GET_FIRSTWSMESSAGESBYSESSIONUSERIDORDERBYIDDESC_SUCCESS, GET_FIRSTWSMESSAGESBYSESSIONUSERIDORDERBYIDDESC_FAILURE],
    endpoint: "wsMessages/search/findFirstBySessionUserIdOrderByIdDesc".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};
export var PUSH_WSMESSAGE = "PUSH_WSMESSAGE";
export var pushWsMessage = function pushWsMessage(wsMessage, sessionUserId) {
  return {
    wsMessage: wsMessage,
    sessionUserId: sessionUserId,
    type: PUSH_WSMESSAGE
  };
};