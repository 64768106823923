function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import * as ActionTypes from "../../actions";

// Updates an entity cache in response to any action with response.entities.
var groupEntities = function groupEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byMembersUserId: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_GROUPSBYMEMBERSUSERID_SUCCESS:
      var byMembersUserId = _objectSpread({}, state.byMembersUserId);
      var key = action.membersUserId;
      if (byMembersUserId[key] && action.maxMessageId !== undefined) byMembersUserId[key] = byMembersUserId[key].concat(action.response);else byMembersUserId[key] = action.response;
      return Object.assign({}, state, {
        byMembersUserId: byMembersUserId
      });
    case ActionTypes.PUSH_MESSAGE:
      var byMembersUserId = _objectSpread({}, state.byMembersUserId);
      var groups = byMembersUserId[action.authUserId] || [];
      var group = groups.find(function (grp) {
        return grp.id === action.message.group.id;
      });
      if (group) {
        var groupIndex = groups.findIndex(function (grp) {
          return grp.id === action.message.group.id;
        });
        groups = [groups[groupIndex]].concat(_toConsumableArray(groups.slice(0, groupIndex)), _toConsumableArray(groups.slice(groupIndex + 1)));
      } else {
        group = action.message.group;
        groups = [group].concat(_toConsumableArray(groups));
      }
      group.lastMessage = action.message;
      var ownState = action.message.states.find(function (state) {
        return state.user.id === action.authUserId;
      });
      ownState && !ownState.viewedAt && group.unreadMessageCount++;
      byMembersUserId[action.authUserId] = groups;
      return Object.assign({}, state, {
        byMembersUserId: byMembersUserId
      });
    case ActionTypes.PUSH_MESSAGESTATE:
      var byMembersUserId = _objectSpread({}, state.byMembersUserId);
      var key = action.authUserId;
      byMembersUserId[key] && byMembersUserId[key].forEach(function (group) {
        if (group.id === action.messageState.message.group.id && !action.messageState.viewedAt) group.unreadMessageCount++;else if (group.id === action.messageState.message.group.id && action.messageState.viewedAt) group.unreadMessageCount--;
      });
      return Object.assign({}, state, {
        byMembersUserId: byMembersUserId
      });
    default:
      return state;
  }
};
export default groupEntities;