import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var goalMemberApiCalls = {
  loadGoalMembersByUserIdAndStateInAndConfirmedAtNotNull: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.userId, action.states].join("-");
    },
    types: [ActionTypes.GET_GOALMEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_REQUEST, ActionTypes.GET_GOALMEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_SUCCESS, ActionTypes.GET_GOALMEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_FAILURE, ActionTypes.RESET_GET_GOALMEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL]
  }),
  postGoalMember: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.goalMember.userId, action.goalMember.followeeUsername].join("-");
    },
    types: [ActionTypes.POST_GOALMEMBER_REQUEST, ActionTypes.POST_GOALMEMBER_SUCCESS, ActionTypes.POST_GOALMEMBER_FAILURE, ActionTypes.RESET_POST_GOALMEMBER]
  }),
  deleteGoalMember: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.goalMember.id.toString();
    },
    types: [ActionTypes.DELETE_GOALMEMBER_REQUEST, ActionTypes.DELETE_GOALMEMBER_SUCCESS, ActionTypes.DELETE_GOALMEMBER_FAILURE, ActionTypes.RESET_DELETE_GOALMEMBER]
  }),
  postGoalMemberExclusionPreferences: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_GOALMEMBEREXCLUSIONPREFERENCES_REQUEST, ActionTypes.POST_GOALMEMBEREXCLUSIONPREFERENCES_SUCCESS, ActionTypes.POST_GOALMEMBEREXCLUSIONPREFERENCES_FAILURE, ActionTypes.RESET_POST_GOALMEMBEREXCLUSIONPREFERENCES]
  })
};
export default goalMemberApiCalls;