import merge from "lodash/merge";
import * as ActionTypes from "../../actions";

// Updates an entity cache in response to any action with response.entities.
var marketVariantInvestmentItemEntities = function marketVariantInvestmentItemEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byId: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_EVENTLISTITEMSBYUSERIDANDEVENTTYPEIDIN_SUCCESS:
      var byId = {};
      action.response.forEach(function (eventListItem) {
        return merge(byId, eventListItem.marketVariantInvestmentItemById);
      });
      return merge({}, state, {
        byId: byId
      });
    default:
      return state;
  }
};
export default marketVariantInvestmentItemEntities;