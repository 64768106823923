function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import * as ActionTypes from "../../actions";

// Updates an entity cache in response to any action with response.entities.
var goalTypeEntities = function goalTypeEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byId: {},
    byIdIn: {},
    mostUsed: []
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_GOALTYPES_SUCCESS:
      if (!action.response._embedded) return state;
      var byId = _objectSpread({}, state.byId);
      action.response._embedded.goalTypes.forEach(function (goalType) {
        return byId[goalType.id] = goalType;
      });
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.POST_GOALTYPE_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      var embedded = action.response._embedded;
      byId[embedded.id] = embedded;
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.POST_GOALTYPEPICTURE_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      var goalType = byId[action.goalType.id];
      if (goalType) {
        var goalType = _objectSpread({}, goalType);
        goalType.picture = action.response._embedded;
        byId[action.goalType.id] = goalType;
      }
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.GET_GOALTYPESBYIDIN_SUCCESS:
      var byIdIn = _objectSpread({}, state.byIdIn);
      var embedded = action.response._embedded;
      var key = action.ids.join(",");
      byIdIn[key] = embedded.goalTypes;
      return Object.assign({}, state, {
        byIdIn: byIdIn
      });
    case ActionTypes.GET_GOALMEMBERSVIEWBYUSERID_SUCCESS:
      return Object.assign({}, state, {
        mostUsed: action.response.mostUsedGoalTypes
      });
    default:
      return state;
  }
};
export default goalTypeEntities;