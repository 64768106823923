import status from "./status";
import * as ActionTypes from "../../actions";

//Updates the status apiCalls data for different api call.
var goalMembersTabApiCalls = {
  loadGoalMembersTabByGoalIdAndBalanceInstant: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.goalId, action.balanceInstant].join("-");
    },
    types: [ActionTypes.GET_GOALMEMBERSTABBYGOALIDANDBALANCEINSTANT_REQUEST, ActionTypes.GET_GOALMEMBERSTABBYGOALIDANDBALANCEINSTANT_SUCCESS, ActionTypes.GET_GOALMEMBERSTABBYGOALIDANDBALANCEINSTANT_FAILURE, ActionTypes.RESET_GET_GOALMEMBERSTABBYGOALIDANDBALANCEINSTANT]
  })
};
export default goalMembersTabApiCalls;