import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var groupApiCalls = {
  fetchGroupsByMembersUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.membersUserId.toString();
    },
    types: [ActionTypes.GET_GROUPSBYMEMBERSUSERID_REQUEST, ActionTypes.GET_GROUPSBYMEMBERSUSERID_SUCCESS, ActionTypes.GET_GROUPSBYMEMBERSUSERID_FAILURE, ActionTypes.RESET_GET_GROUPSBYMEMBERSUSERID]
  })
};
export default groupApiCalls;